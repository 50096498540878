import React from "react";
import ReactGA from "react-ga";
import "font-awesome/css/font-awesome.min.css";
import Navbar from "./components/Navbar";
import Carousel from "./components/Carousel";
import Salon from "./components/Salon";
import Prestation from "./components/Prestation";
import Temoignage from "./components/Temoignage";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Carousel />
      <Salon />
      <Prestation />
      <Temoignage />
      <Contact />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

function initializeAnalytics() {
  ReactGA.initialize("UA-172006937-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

initializeAnalytics();

export default App;
