import React, { useState, useEffect } from "react";
import "./Salon.css";

const Salon = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [horaires, setHoraires] = useState([]);

  const openModal = (image) => {
    setModalImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    fetch("https://hairdedetente.fr/api/horaires")
      .then((response) => response.json())
      .then((data) => setHoraires(data));
  }, []);

  return (
    <div id="salon" className="salon-section centered-section">
      <h1>LE SALON DE COIFFURE HAIR DE DÉTENTE À PÉNESTIN</h1>
      <img
        src={`${process.env.PUBLIC_URL}/images/salon.png`}
        alt="Salon Hair De Détente"
        className="salon-image"
      />
      <div className="salon-text">
        <p>
          <span className="bold-text">
            Depuis 2009, le salon "Hair de Détente" est votre destination
            privilégiée pour tous vos besoins en coiffure à Pénestin.
          </span>
          <br />
          <br />
          <span className="bold-text">
            Fondé par Mélanie, une styliste-visagiste certifiée,
          </span>{" "}
          notre établissement vous offre un éventail complet de services,
          adaptés à chaque membre de la famille : des coupes pour femmes, hommes
          et enfants, jusqu'aux techniques de coloration avancées et aux soins
          capillaires spécialisés.
        </p>

        <p>
          <span className="bold-text">Offre étudiante spéciale</span> :
          Bénéficiez de tarifs préférentiels du mois de Septembre au mois de
          Juin, correspondant à l'année académique
          <span className="bold-text">*</span> <br />
          <span className="bold-text">*</span> Tarif applicable de Septembre à
          Juin, c'est à dire durant l'année scolaire. <br />
          <br />
          <span className="bold-text">
            Soucieux de votre confort et de votre bien-être,
          </span>{" "}
          "Hair de Détente" vous accueille dans un cadre relaxant, équipé de
          fauteuils massants pour vos soins capillaires et d'une climatisation
          pour assurer un environnement agréable tout au long de l'année. <br />
          <span className="bold-text">
            Engagés dans une démarche de qualité,
          </span>{" "}
          nous avons choisi d'utiliser exclusivement des produits de marques
          européennes, certifiées <span className="bold-text">CE.</span>
        </p>

        <p>
          <span className="bold-text">
            Si vous êtes à la recherche d'une expérience capillaire
            exceptionnelle à Pénestin,
          </span>{" "}
          ne cherchez pas plus loin.{" "}
          <span className="bold-text">
            Prenez rendez-vous dès aujourd'hui chez "Hair de Détente".
          </span>
        </p>
        <br />

        <h3>LES HORAIRES</h3>
        <ul className="horaires-list">
          {horaires.map((info, index) => (
            <li key={index}>
              <i className="fas fa-clock icon"></i> {info}
            </li>
          ))}
        </ul>
        <p>
          <i className="fas fa-phone-alt icon"></i>02.99.90.30.15 <br />
          <i className="fas fa-map-marker-alt icon"></i>50 Rue de la plage,
          56760, Pénestin
        </p>
      </div>

      <div className="salon-images">
        <img
          src={`${process.env.PUBLIC_URL}/images/salon-1.jpg`}
          alt="Salon 1"
          className="salon-img img-thumbnail"
          onClick={() =>
            openModal(`${process.env.PUBLIC_URL}/images/salon-1.jpg`)
          }
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/salon-2.jpg`}
          alt="Salon 2"
          className="salon-img img-thumbnail"
          onClick={() =>
            openModal(`${process.env.PUBLIC_URL}/images/salon-2.jpg`)
          }
        />
        <img
          src={`${process.env.PUBLIC_URL}/images/salon-3.jpg`}
          alt="Salon 3"
          className="salon-img img-thumbnail"
          onClick={() =>
            openModal(`${process.env.PUBLIC_URL}/images/salon-3.jpg`)
          }
        />
      </div>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <img src={modalImage} alt="Vue modale" className="modal-image" />
        </div>
      )}
    </div>
  );
};

export default Salon;
