import React, { useState } from "react";
import "./Navbar.css";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <nav className="navbar">
      <div className={`brand ${showMenu ? "hide-on-mobile" : ""}`}>
        {" "}
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.jpg`}
          alt="Hair de Détente"
          width="288"
          height="60"
        />
      </div>
      <div
        className={`menu-toggle ${showMenu ? "menu-open" : "menu-closed"}`}
        onClick={() => setShowMenu(!showMenu)}
      >
        {showMenu ? "x" : "☰"}
      </div>

      <div className={`menu ${showMenu ? "show" : ""}`}>
        <button onClick={() => scrollToSection("salon")} className="menu-item">
          <i className="fa-solid fa-scissors"></i> SALON
        </button>
        <button
          onClick={() => scrollToSection("prestations")}
          className="menu-item"
        >
          <i className="fa-solid fa-star"></i> PRESTATIONS
        </button>
        <button
          onClick={() => scrollToSection("temoignages")}
          className="menu-item"
        >
          <i className="fa-solid fa-comments"></i> TÉMOIGNAGES
        </button>
        <button
          onClick={() => scrollToSection("contact")}
          className="menu-item"
        >
          <i className="fa-solid fa-envelope"></i> CONTACT
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
