import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./Contact.css";

const Contact = () => {
  const mapStyles = {
    height: "30vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: 47.47464219992792,
    lng: -2.4858461785571992,
  };

  const [horaires, setHoraires] = useState([]);

  useEffect(() => {
    fetch("https://hairdedetente.fr/api/horaires")
      .then((response) => response.json())
      .then((data) => setHoraires(data));
  }, []);

  return (
    <section id="contact" className="section contact-section">
      <div className="contact-header">
        <img
          src={`${process.env.PUBLIC_URL}/images/envelope.png`}
          alt="Contact Logo"
          className="contact-logo"
        />
        <h1>CONTACT</h1>
      </div>
      <div className="columns">
        <div className="column">
          <div className="google-map">
            <LoadScript googleMapsApiKey="AIzaSyAiALvDVBuMXpnqeB9NspV4qb8pScA9kvo">
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={14}
                center={defaultCenter}
              >
                <Marker position={defaultCenter} />
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
        <div className="column">
          <div className="box mb-4" style={{ height: "30vh" }}>
            <h3 className="title is-4 centered-text">Horaires</h3>
            <ul className="horaires-list">
              {horaires.map((info, index) => (
                <li key={index}>
                  <i className="fas fa-clock icon"></i> {info}
                </li>
              ))}
            </ul>
            <p className="centered-text">
              <i className="fas fa-phone-alt icon"></i>02.99.90.30.15 <br />
              <i className="fas fa-map-marker-alt icon"></i>50 Rue de la plage,
              56760, Pénestin
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
