import React, { useState, useEffect } from "react";
import "./Carousel.css";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = ["slider1.jpg", "slider2.jpg", "slider3.jpg"];

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentIndex, images.length]);

  return (
    <div className="carousel">
      <img
        src={`${process.env.PUBLIC_URL}/images/${images[currentIndex]}`}
        alt={`Slide ${currentIndex + 1}`}
        className="carousel-image"
      />
    </div>
  );
};

export default Carousel;
