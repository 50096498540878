import React, { useEffect, useState } from "react";
import "./Prestation.css";

const Prestations = () => {
  const [prixData, setPrixData] = useState([]);

  useEffect(() => {
    fetch("/api/prix")
      .then((res) => res.json())
      .then((data) => setPrixData(data))
      .catch((error) =>
        console.error(
          "Erreur lors de la récupération des données de prix:",
          error
        )
      );
  }, []);

  const groupedData = {};
  prixData.forEach((prix) => {
    if (!groupedData[prix.section]) {
      groupedData[prix.section] = [];
    }
    groupedData[prix.section].push(prix);
  });

  const formatText = (text) => {
    const parts = text.split("*");
    return (
      <>
        {parts[0]}
        <span style={{ color: "red" }}>*</span>
        {parts[1]}
      </>
    );
  };

  return (
    <div id="prestations" className="prestation-section centered-section">
      <div className="prestation-header">
        <img
          src={`${process.env.PUBLIC_URL}/images/calculator.png`}
          alt="Logo"
          className="prestation-logo"
        />
        <h1>LES PRESTATIONS</h1>
      </div>
      <div className="prestations-text">
        <p>
          <span className="bold-text">Le forfait comprend :</span> Shampoing
          traitant + coupe ainsi que produits de coiffage.
        </p>
        <p>
          <span className="bold-text">Soin :</span> 10€
        </p>
        <p>
          <span className="bold-text">Tarif sans coupe :</span> 15€ en moins
        </p>
        <p>
          <span className="bold-text">{"Tarif < 18 ans (filles) :"}</span> 4€ en
          moins (sur forfait Coupe Brushing ou Coupe Coiffage)
        </p>
        <p>
          <span className="bold-text">{"Tarif < 10 ans (filles) :"}</span> 6€ en
          moins (sur forfait Coupe Brushing ou Coupe Coiffage)
        </p>
      </div>

      <div className="price-table-container">
        {Object.keys(groupedData).map((section, index) => (
          <div key={index}>
            <table className="price-table">
              <thead>
                <tr className="section-header">
                  <th>{section}</th>
                  <th>Court</th>
                  <th>Mi-Long</th>
                  <th>Long</th>
                  <th>Très Long</th>
                </tr>
              </thead>
              <tbody>
                {groupedData[section].map((prix, i) => (
                  <tr key={i}>
                    <td>{prix.type_prestation}</td>
                    <td>
                      {prix.court.includes("*")
                        ? formatText(prix.court)
                        : prix.court}
                    </td>
                    <td>
                      {prix.mi_long.includes("*")
                        ? formatText(prix.mi_long)
                        : prix.mi_long}
                    </td>
                    <td>
                      {prix.long.includes("*")
                        ? formatText(prix.long)
                        : prix.long}
                    </td>
                    <td>
                      {prix.tres_long.includes("*")
                        ? formatText(prix.tres_long)
                        : prix.tres_long}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <p className="table-note">
          <span className="red-asterisk">*</span>
          DÉPENDANT DU TEMPS PASSÉ POUR LA CRÉATION.
        </p>
      </div>
      <p className="additional-note">
        Note : Toutes prestations en dehors des horaires d'ouverture fera
        l'objet d'une majoration de 20% sur la facture globale.
      </p>
    </div>
  );
};

export default Prestations;
