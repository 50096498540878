import React, { useEffect, useState } from "react";
import "./Temoignage.css";

const Temoignages = () => {
  const [temoignageData, setTemoignageData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetch("/api/temoignages")
      .then((res) => res.json())
      .then((data) => setTemoignageData(data))
      .catch((error) =>
        console.error("Erreur lors de la récupération des témoignages:", error)
      );
  }, []);

  const prevTemoignage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : temoignageData.length - 1
    );
  };

  const nextTemoignage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < temoignageData.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div id="temoignages" className="temoignages-section">
      <div className="temoignage-header">
        <img
          src={`${process.env.PUBLIC_URL}/images/temoignages.png`}
          alt="Logo"
          className="temoignage-logo"
        />
        <h1>TÉMOIGNAGES DE NOS CLIENTS</h1>
      </div>
      {temoignageData.length > 0 && (
        <div className="temoignages-container">
          <div className="temoignage-card">
            <div className="temoignage-info">
              <img
                src={temoignageData[currentIndex].photo_profil}
                alt={temoignageData[currentIndex].nom}
                className="photo-profil"
              />
              <h3>{temoignageData[currentIndex].nom}</h3>
            </div>
            <p className="mb-3">{temoignageData[currentIndex].avis}</p>
            <p className="mb-3">
              <strong>Services :</strong>{" "}
              {temoignageData[currentIndex].services_effectues}
            </p>
            <p className="mb-3">
              <strong>Date de l'avis :</strong>{" "}
              {new Date(temoignageData[currentIndex].date).toLocaleDateString()}
            </p>
            <p className="mb-3">
              <strong>Note :</strong>{" "}
              {"⭐".repeat(temoignageData[currentIndex].etoiles)}
            </p>
            <div className="button-container">
              <button className="button is-info mr-3" onClick={prevTemoignage}>
                Avis Précédent
              </button>
              <button className="button is-primary" onClick={nextTemoignage}>
                Avis Suivant
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Temoignages;
