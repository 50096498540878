import React, { useState } from "react";
import "./Footer.css";

const Footer = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            © HAIR DE DÉTENTE -{" "}
            <span className="modal-trigger" onClick={openModal}>
              MENTIONS LÉGALES
            </span>
            <br />
            Développé par Kévin BOILEUX
          </p>
        </div>
      </footer>

      {isModalOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content">
            <h3 className="modal-title">MENTIONS LÉGALES</h3>
            <p className="modal-date">En vigueur au 14/10/2023</p>
            <p>
              Conformément aux dispositions des Articles 6-III et 19 de la Loi
              n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
              numérique, dite L.C.E.N., il est porté à la connaissance des
              Utilisateurs du site hairdedetente.fr les présentes mentions
              légales. La connexion et la navigation sur le site Hair de Détente
              par l’Utilisateur implique acceptation intégrale et sans réserve
              des présentes mentions légales.
            </p>
            <h4>ARTICLE 1 : L’ÉDITEUR</h4>
            <p>
              L’édition et la direction de la publication du site
              hairdedetente.fr est assurée par Kévin Boileux dont l'adresse e-mail est
              danielboileux@gmail.com
            </p>
            <h4>ARTICLE 2 : L’HÉBERGEUR</h4>
            <p>
              L'hébergeur du site hairdedetente.fr est la Société OVH, dont le
              siège social est situé au Siège social : 2 rue Kellermann - 59100
              Roubaix - France.
            </p>
            <h4>ARTICLE 3 : ACCÈS AU SITE</h4>
            <p>
              Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de
              force majeure, interruption programmée ou non et pouvant avoir une
              nécessité de maintenance. En cas de modification, interruption ou
              suspension des services le site hairdedetente.fr ne saurait être
              tenu responsable.
            </p>
            <h4>ARTICLE 4 : COLLECTE DES DONNÉES</h4>
            <p>
              Le site est exempté de déclaration à la Commission Nationale
              Informatique et Libertés (CNIL) dans la mesure où il ne collecte
              aucune donnée concernant les utilisateurs.
            </p>
            <h4>ARTICLE 5 : COOKIES</h4>
            <p>
              L’Utilisateur est informé que lors de ses visites sur le site, un
              cookie peut s’installer automatiquement sur son logiciel de
              navigation. En naviguant sur le site, il les accepte. Un cookie
              est un élément qui ne permet pas d’identifier l’Utilisateur mais
              sert à enregistrer des informations relatives à la navigation de
              celui-ci sur le site Internet. L’Utilisateur pourra désactiver ce
              cookie par l’intermédiaire des paramètres figurant au sein de son
              logiciel de navigation.
            </p>
            <h4>ARTICLE 6 : PROPRIÉTÉ INTELLECTUELLE</h4>
            <p>
              Toute utilisation, reproduction, diffusion, commercialisation,
              modification de toute ou partie du site hairdedetente.fr, sans
              autorisation de l’Editeur est prohibée et pourra entraînée des
              actions et poursuites judiciaires telles que notamment prévues par
              le Code de la propriété intellectuelle et le Code civil.
            </p>
          </div>
          <div className="close-button-container">
            <button className="button is-primary close-button" onClick={closeModal}>
              Fermer
            </button>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={closeModal}
          ></button>
        </div>
      )}
    </>
  );
};

export default Footer;
